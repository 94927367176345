<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                <v-icon color="primary" large left>$vuetify.icons.delete</v-icon>
                Zuzahlungsrückforderung löschen
            </v-card-title>
            <v-card-text class="text-body-1 text--primary">
                <p v-if="zzrItem.pNr" class="text-justify text-hyphenate mb-0">
                    Sind Sie sicher, dass Sie die Position die {{zzrItem.pNr}} aus der Zuzahlungsrückforderung mit der Auftragsnummer {{zzrItem.asP_ID}} löschen wollen?
                </p>
                <p v-else-if="zzrItem" class="text-justify text-hyphenate mb-0">
                    Sind Sie sicher, dass Sie die Zuzahlungsrückforderung mit der Auftragsnummer {{zzrItem.asP_ID}} löschen wollen?
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="onDelete">
                    Ja
                </v-btn>
                <v-btn color="primary" @click="onCancel">
                    Nein
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'DialogZzrefundLoeschen',
        props: {
            showDialog: false,
            zzrItem: {}
        },
        methods: {
            onDelete() {
                console.log('this.zzrItem im Dialog: ', this.zzrItem);

                this.$emit('delete', true);
            },

            onCancel() {
                this.$emit('delete', false)
            }
        },
    }
</script>