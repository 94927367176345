<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="12">

                <v-container fluid class="px-0 pb-0">
                    <v-form ref="ZzRefundStatusForm" v-model="formFieldsValid" @submit.prevent="trySubmitZzRefundStatus" class="pa-3">
                        <v-row>
                            <v-col cols="12" sm="6" lg="2">
                                <v-select :label="selectedKasse === null || selectedKasse.length == 0 ? 'Alle Kostenträger' : 'Kostenträger'"
                                          :items="mandanten" item-text="bezeichnung" item-value="mandant" v-model="selectedKasse"
                                          :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                                          class="custom-dense pointer" clearable multiple />

                            </v-col>
                            <v-col cols="12" sm="6" lg="2">
                                <v-select :label="selectedStatus === null || selectedStatus.length == 0 ? 'Alle Status' : 'Status'"
                                          :items="zzStatus" item-text="Bezeichnung" item-value="Status" v-model="selectedStatus"
                                          :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                                          class="custom-dense pointer" clearable multiple />

                            </v-col>
                            <v-col cols="12" sm="6" lg="2">
                                <v-text-field label="Versichertennummer" :value="versNr" @input="(v) => setVersNr(v)"
                                              v-mask="'A#########'" autocomplete="off" dense clearable />

                            </v-col>
                            <v-col cols="auto">
                                <v-btn color="primary" type="submit" :disabled="!formFieldsValid">
                                    Suchen
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-1 py-2" dense>
                        <v-col cols="12" class="pr-6">
                            <v-data-table :headers="headersAuftrag" :items="zzItems" item-key="asP_ID"
                                          :expanded="[expandedItem]" :value="[selectedItem]" :items-per-page="10"
                                          :dense="$vuetify.breakpoint.mdAndDown" :loading="loadingList"
                                          class="font-weight-semibold text-no-wrap text-body-2 text-lg-body-1
                                                 scroll-gradient elevation-2 rounded-0 alternating-row-colors"
                                          single-select single-expand>

                                <template v-slot:item.grund="{ item }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">
                                                {{ getGrundText(item.grund, true) }}
                                            </span>
                                        </template>
                                        <span>{{ getGrundText(item.grund) }}</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:item.zzBetrag="{ item }">
                                    {{ formatCurrency(item.zzBetrag) }}
                                </template>
                                <template v-slot:item.kostentraeger="{ item }">
                                    {{ getMandantText(item.kostentraeger) }}
                                </template>
                                <template v-slot:item.anzahlBelege="{ item }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn color="primary" v-on="{ ...tooltip }"
                                                   @click="togglePositionen(item)"
                                                   :small="$vuetify.breakpoint.mdAndDown" depressed>

                                                {{ item.anzahlBelege }}
                                            </v-btn>
                                        </template>
                                        <span>Bitte anklicken, um die Auftragspositionen zu öffnen</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:item.eDatum="{ item }">
                                    {{ item.eDatum ? moment(item.eDatum).format('DD.MM.YYYY') : null }}
                                </template>
                                <template v-slot:item.status="{ item }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">

                                            <v-chip v-on="on" :color="getStatusColor(item.status)" :small="$vuetify.breakpoint.mdAndDown">
                                                {{ getStatusAuftragText(item.status || $store.state.defCache.zZStatus[0].Status, true) }}
                                            </v-chip>


                                        </template>
                                        <span>
                                            {{ getStatusAuftragText(item.status || $store.state.defCache.zZStatus[0].Status, false)  }}
                                        </span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:item.begruendung="{ item }">
                                    <v-tooltip v-if="item.zrBegruendung" bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn v-on="{ ...tooltip }" @click="toggleBegruendung(item)"
                                                   :small="$vuetify.breakpoint.mdAndDown" icon>

                                                <v-icon color="primary">
                                                    $vuetify.icons.formatList
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Bitte anklicken, um den Begründungstext zu öffnen</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:item.anhang="{ item }">
                                    <div>
                                        <!-- open menu only after data is loaded, else on first click it will overflow to the right -->
                                        <v-menu :open-on-click="false" :value="openMenuItem == item" @input="openMenuItem = null" offset-y left>
                                            <template v-slot:activator="{ on: menu, attrs }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <!-- don't show tooltip if menu is open -->
                                                        <v-btn v-bind="attrs" v-on="!openMenuItem ? { ...tooltip, ...menu } : { ...menu }"
                                                               @click="openAnhangList(item)" :loading="loadingMenuItem == item"
                                                               :small="$vuetify.breakpoint.mdAndDown" text>

                                                            <v-icon color="primary" :small="$vuetify.breakpoint.mdAndDown">
                                                                $vuetify.icons.fileDownload
                                                            </v-icon>
                                                            <span class="font-weight-semibold text-body-2 text-lg-body-1">
                                                                ({{ item.anzahlDoc }})
                                                            </span>
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        Bitte anklicken, um
                                                        {{ item.anzahlDoc == 1 ? 'das Dokument' : 'die Dokumente' }}
                                                        zu sichten
                                                    </span>
                                                </v-tooltip>
                                            </template>
                                            <v-list dense>
                                                <v-list-item v-for="(item, index) in item.anhang" :key="index" @click="downloadfile(item)">
                                                    <v-list-item-action>
                                                        <v-list-item-title class="text-body-1">
                                                            {{ item.originalName }}
                                                        </v-list-item-title>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td v-bind:colspan="headers.length" class="pa-0">
                                        <v-container fluid class="pa-4">
                                            <v-row v-show="showBegruendung" class="align-center" dense>
                                                <v-col cols="12">
                                                    <v-card borderless>
                                                        <v-card-title class="text-h5">
                                                            Begründung
                                                        </v-card-title>
                                                        <v-card-text class="text-body-1 text--primary font-weight-semibold">
                                                            {{ item.zrBegruendung }}
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row v-show="showPositionen" class="align-center" dense>
                                                <v-col cols="12">
                                                    <v-card borderless>
                                                        <v-card-text class="text-body-1 text--primary font-weight-semibold">
                                                            <v-data-table :headers="headersBeleg" :items="item.positionen" item-key="belegNr"
                                                                          :items-per-page="10" :loading="loadingItem == item"
                                                                          :dense="$vuetify.breakpoint.mdAndDown"
                                                                          class="font-weight-semibold text-no-wrap text-body-2 text-lg-body-1
                                                                            scroll-gradient elevation-2 rounded-0 alternating-row-colors"
                                                                          single-select single-expand>

                                                                <template v-slot:item.belegNr="{ item }">
                                                                    {{ item.belegNr || 'keine Angabe' }}
                                                                </template>
                                                                <template v-slot:item.eRezept="{ item }">
                                                                    {{ item.eRezept || 'keine Angabe' }}
                                                                </template>
                                                                <template v-slot:item.aDatum="{ item }">
                                                                    {{ item.aDatum ? moment(item.aDatum).format('DD.MM.YYYY') : 'keine Angabe' }}
                                                                </template>
                                                                <template v-slot:item.zzBetrag="{ item }">
                                                                    {{ formatCurrency(item.zzBetrag) }}
                                                                </template>
                                                                <template v-slot:item.status="{ item }">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-chip v-on="on" :color="getStatusColor(item.status)" :small="$vuetify.breakpoint.mdAndDown">
                                                                                {{ getStatusPositionText(item.status || $store.state.defCache.zZPosStatus[0].Status, true) }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <span>
                                                                            {{ getStatusPositionText(item.status || $store.state.defCache.zZPosStatus[0].Status, false)  }}
                                                                        </span>
                                                                    </v-tooltip>

                                                                </template>
                                                                <template v-slot:item.vorgangNr="{ item }">
                                                                    {{ item.vorgangNr || 'keine Angabe' }}
                                                                </template>
                                                                <template v-slot:item.actions="{ item }">
                                                                    <v-btn @click="selectedZzrItem=item; showDialogDelItem =true " :small="$vuetify.breakpoint.mdAndDown" icon
                                                                           v-if="item.status === 0 && selectedItem.status === 0 && zzItemPos.length > 1">
                                                                        <v-icon color="primary">
                                                                            $vuetify.icons.delete
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>

                                                            </v-data-table>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </td>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-btn @click="selectedZzrItem=item; showDialogDelItem =true " :small="$vuetify.breakpoint.mdAndDown" icon v-if="item.status === 0">
                                        <v-icon color="primary">
                                            $vuetify.icons.delete
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="auto">
                            <v-alert v-if="alertMessage" type="error" class="my-auto py-3 text-body-1"
                                     style="white-space: pre-line;" min-height="52">

                                {{ alertMessage }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <dialog-zzrefund-loeschen :showDialog.sync="showDialogDelItem" @delete="deleteZzrItem" :zzrItem="selectedZzrItem" />

    </v-container>
</template>
<script>
    const headersAuftrag = [
        { text: 'Auftragsnummer', value: 'asP_ID' },
        { text: 'Grundlage', value: 'grund' },
        { text: 'Versichertennr.', value: 'versNr' },
        { text: 'Kostenträger', value: 'kostentraeger' },
        { text: '# Belege', value: 'anzahlBelege', align: 'center' },
        { text: 'Eingangsdatum', value: 'eDatum', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Begründung', value: 'begruendung', align: 'center' },
        { text: 'Anhang', value: 'anhang' },
        { text: '', value: 'actions', sortable: false },
    ];

    const headersBeleg = [
        { text: 'Belegnummer', value: 'belegNr', width: '20%' },
        { text: 'E-Rezept-ID', value: 'eRezept', width: '20%' },
        { text: 'Abgabedatum', value: 'aDatum', align: 'center', width: '20%' },
        { text: 'Gesamtbetrag Zuzahlung (€)', value: 'zzBetrag', align: 'end', width: '20%' },
        { text: 'Status', value: 'status', align: 'center', width: '20%' },
        { text: 'Ihr Kennzeichen', value: 'vorgangNr', width: '20%' },
        { text: '', value: 'actions', sortable: false },
    ];

    import eventBus from '@/main';
    import DialogZzrefundLoeschen from '@/components/DialogZzrefundLoeschen.vue'
    export default {
        name: 'ZzRefundStatus',
        components: {
            DialogZzrefundLoeschen
        },
        data: () => ({
            // infra
            selectedKasse: null,
            selectedStatus: null,
            versNr: null,
            formFieldsValid: true,
            selectedItem: null,
            expandedItem: null,
            showBegruendung: false,
            showPositionen: false,
            loadingItem: null,
            openMenuItem: null,
            loadingMenuItem: null,
            loadingList: false,
            alertMessage: null,

            // model
            headersAuftrag,
            headersBeleg,
            zzItems: [],
            zzItemPos: [],
            selectedZzrItem: {},
            showDialogDelItem: false,
        }),
        mounted() {
            this.trySubmitZzRefundStatus();
        },
        created() {
            eventBus.$on('reloadZzRefundStatus', () => {
                // clear search parameters, then refresh
                this.$refs.ZzRefundStatusForm?.reset();
                this.trySubmitZzRefundStatus();
            });
        },
        computed: {
            mandanten() {
                let ml = this.$store.state.currentUser?.mandanten?.map(m => ({ ...m }));
                return ml ?? [];
            },
            zzStatus() {
                return this.$store.state?.defCache?.zZStatus ?? [];
            },
        },

        methods: {
            setVersNr(value) {
                this.versNr = value?.toUpperCase();
            },

            getStatusColor(status) {
                switch (status) {
                    default: return 'gray';
                }
            },

            getStatusAuftragText(v, kurz = false) {

                if (kurz) 
                    return this.$store.state.defCache?.zZStatus?.find(s => s.Status == v)?.Bezeichnung;
                return this.$store.state.defCache?.zZStatus?.find(s => s.Status == v)?.Beschreibung;
            },

            //getStatusAuftragTooltip(v) {
            //    return this.$store.state.defCache?.zZStatus?.find(s => s.Status == v)?.Beschreibung;
            //},

            getStatusPositionText(v, kurz = false) {
                if (kurz) 
                    return this.$store.state.defCache?.zZPosStatus?.find(s => s.Status == v)?.Bezeichnung;
                return this.$store.state.defCache?.zZPosStatus?.find(s => s.Status == v)?.Beschreibung;
            },
            //getStatusPositionTooltip(v) {
            //    return this.$store.state.defCache?.zZPosStatus?.find(s => s.Status == v)?.Beschreibung;
            //},

            getGrundText(v, kurz = false) {

                if (kurz) 
                    return this.$store.state.defCache?.rueckforderungGrund?.find(g => g.Grund == v)?.Bezeichnung;
                

                return this.$store.state.defCache?.rueckforderungGrund?.find(g => g.Grund == v)?.Beschreibung;
            },

            getMandantText(v) {
                return this.$store.state.currentUser?.mandanten?.find(m => m.mandant == v)?.bezeichnung;
            },

            formatCurrency(v) {
                // currency sign in header, thus decimal
                const options = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
                return new Intl.NumberFormat('de-DE', options).format(v * 0.01);
            },

            toggleItem(item) {
                this.selectedItem = item;
                this.expandedItem = this.showBegruendung || this.showPositionen ? item : null;
            },

            toggleBegruendung(item) {
                if (this.selectedItem == item) {
                    this.showBegruendung = !this.showBegruendung;
                }
                else {
                    this.showBegruendung = true;
                    this.showPositionen = false;
                }

                this.toggleItem(item);
            },

            async togglePositionen(item) {
                if (this.selectedItem == item) {
                    this.showPositionen = !this.showPositionen;
                }
                else {
                    this.showPositionen = true;
                    this.showBegruendung = false;
                }

                this.toggleItem(item);
                await this.loadPositionenAndAnhang(item);
            },

            async openAnhangList(item) {
                try {
                    this.loadingMenuItem = item;
                    await this.loadPositionenAndAnhang(item);
                    this.openMenuItem = item;
                }
                finally {
                    this.loadingMenuItem = null;
                }
            },

            async trySubmitZzRefundStatus() {
                if (this.formFieldsValid) {
                    try {
                        this.loadingList = true;
                        var vKasse = "";
                        if (this.selectedKasse !== null && this.selectedKasse.length > 0) 
                            vKasse = this.selectedKasse?.join('|');
                        else
                            vKasse = this.mandanten.map(e => e.mandant).join('|');

                        const params = {
                            kostentraeger: vKasse, //this.selectedKasse?.join('|'),
                            leistungserbringer: this.$store.state.currentUser.leistungserbringer,
                            status: this.selectedStatus?.join('|'),
                            versNr: this.versNr,
                        };

                        const rsp = await this.$http.get('zuz/rueckforderung', { params });
                        this.zzItems = rsp.data;
                        this.showBegruendung = false;
                        this.showPositionen = false;
                        this.toggleItem(null);
                    }
                    catch (error) {
                        this.alertMessage = error?.response?.data?.message || "Beim Abruf der Aufträge kam es zu einem Fehler.";
                    }
                    finally {
                        this.loadingList = false;
                    }
                }
            },

            async loadPositionenAndAnhang(item) {
                if (!item.positionen || !item.anhang) {
                    try {
                        this.loadingItem = item;

                        const url = `zuz/rueckforderung/${item.asP_ID}`;
                        const rsp = await this.$http.get(url);

                        item.positionen = rsp.data.positionen;
                        this.zzItemPos = item.positionen;
                        item.anhang = rsp.data.anhang;
                    }
                    catch (error) {
                        this.alertMessage = error?.response?.data?.message
                            || `Beim Abruf der Detailinformationen zu ${item.asP_ID} kam es zu einem Fehler.`;
                    }
                    finally {
                        this.loadingItem = null;
                    }
                }
            },

            async downloadfile(item) {
                try {
                    const url = `zuz/document/${item.documentID}`;
                    const config = { responseType: 'arraybuffer' };
                    const rsp = await this.$http.get(url, config);

                    const fileUrl = window.URL.createObjectURL(new Blob([rsp.data], { type: 'application/octet-stream' }));
                    const anchor = document.createElement('a');
                    anchor.href = fileUrl;
                    anchor.download = item.originalName;
                    anchor.style = 'display: none';

                    document.body.appendChild(anchor);
                    anchor.click();

                    setTimeout(function () {
                        document.body.removeChild(anchor);
                        window.URL.revokeObjectURL(fileUrl);
                    }, 100);
                }
                catch (error) {
                    this.alertMessage = error?.response?.data?.message || "Beim Abruf der Datei kam es zu einem Fehler.";
                }
            },

            async deleteZzrItem(value) {

                console.log(' zzrItem -->value: ', value);

                // eventBus.$emit('resetLogoutTimer', {});

                if (value) {
                    if (this.showPositionen && this.selectedZzrItem.pNr) {

                        const index = this.zzItemPos.indexOf(this.selectedZzrItem);
                        const it = this.zzItems.find(x => x.asP_ID === this.selectedZzrItem.asP_ID);
                        const itIndex = this.zzItems.indexOf(it);

                        try {
                            this.loadingList = true;
                            let response = await this.$http.post('zuz/deleterueckforderungpos', {
                                ASP_ID: this.selectedZzrItem.asP_ID,
                                PNr: this.selectedZzrItem.pNr,
                                IK: this.$store.state.currentUser.leistungserbringer,
                            });

                            if (response.status === 200) {
                                //Datensatz aus der Tabelle entfernen
                                this.zzItemPos.splice(index, 1);

                                //Anzahl der Positionen in der Anzeige anpassen
                                this.zzItems[itIndex].anzahlBelege--;
                            }
                        }
                        catch (error) {
                            if (error.response) {
                                this.userMessage = error.response.data.message;
                            }
                        }
                        finally {
                            this.loadingList = false;
                        }
                    }
                    else if (this.selectedZzrItem.asP_ID) {

                        const index = this.zzItems.indexOf(this.selectedZzrItem);

                        try {
                            this.loadingList = true;
                            let response = await this.$http.post('zuz/deleterueckforderung', {
                                ASP_ID: this.selectedZzrItem.asP_ID,
                                IK: this.$store.state.currentUser.leistungserbringer,
                            });

                            if (response.status === 200) {
                                //Datensatz aus der Tabelle entfernen
                                this.zzItems.splice(index, 1);
                            }
                        }
                        catch (error) {
                            if (error.response) {
                                this.userMessage = error.response.data.message;
                            }
                        }
                        finally {
                            this.loadingList = false;
                        }

                    }
                }

                this.showDialogDelItem = false;
                this.selectedZzrItem = {};
            },
        },
    };
</script>
<style scoped>
    >>> .v-data-table__expanded__content {
        background-color: white !important;
    }

    >>> .pointer input {
        cursor: pointer;
    }
</style>