import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"justify-start"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"borderless":""}},[_c(VCardTitle,{staticClass:"text-h5 text-md-h4 flex-nowrap align-start"},[_vm._v(" Zuzahlungsrückforderung ")]),_c(VCardText,{staticClass:"text-body-1 text--primary"},[_c(VTabs,{staticClass:"mx-4 text-h5 text-md-h4 flex-nowrap"},[_c(VTab,{attrs:{"disabled":_vm.disableTab}},[_vm._v("Neue Zuzahlungsrückforderung")]),_c(VTab,[_vm._v("Bearbeitungsstatus")]),_c(VTabItem,[_c('ZzRefundNew')],1),_c(VTabItem,[_c('ZzRefundStatus')],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }