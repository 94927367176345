<template>
    <v-container fluid class="pa-0">
        <v-row class="justify-start">
            <v-col cols="12">

                <v-card borderless>
                    <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                        Zuzahlungsrückforderung
                    </v-card-title>
                    <v-card-text class="text-body-1 text--primary">
                        <v-tabs class="mx-4 text-h5 text-md-h4 flex-nowrap">
                            <v-tab :disabled="disableTab">Neue Zuzahlungsrückforderung</v-tab>
                            <v-tab>Bearbeitungsstatus</v-tab>

                            <!-- Neue Rückforderung -->
                            <v-tab-item>
                                <ZzRefundNew />
                            </v-tab-item>

                            <!-- Bearbeitungsstatus -->
                            <v-tab-item>
                                <ZzRefundStatus />
                            </v-tab-item>
                        </v-tabs>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ZzRefundNew from '@/components/ZzRefundNew.vue'
    import ZzRefundStatus from '@/components/ZzRefundStatus.vue'

    export default {
        name: 'ZzRefund',
        components: {
            ZzRefundNew,
            ZzRefundStatus,
        },

        computed: {
            disableTab() {
                return !this.$store.state.module.zzRueckforderungWrite;
            }
        }
    }
</script>