<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="12" lg="12">

                <input type="file"
                       accept=".pdf, .jpg, .jpeg, .png"
                       class="d-none"
                       ref="fileInput"
                       @change="addUploadFiles($event)"
                       @click="$refs.fileInput.value = null"
                       multiple />

                <v-form ref="ZzRefundForm" v-model="formFieldsValid" @submit.prevent="trySubmitZzRefund">
                    <v-card borderless>
                        <v-card-text class="text-body-1 text--primary">
                            <v-container fluid class="pa-0">
                                <v-row class="pt-2">
                                    <v-col cols="3">
                                        <v-select label="Kostenträger" v-model="kostentraeger"
                                                  :items="mandanten" item-text="bezeichnung" item-value="mandant"
                                                  :rules="rules.mandant" :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                                                  :class="{ 'custom-dense': $vuetify.breakpoint.mdAndDown }" hide-details="auto" />
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field label="Versichertennummer" :value="versNr" @input="(v) => setVersNr(v)"
                                                      v-mask="'A#########'" autocomplete="off" :error-messages="versNrError"
                                                      :loading="validatingVersNr" :rules="rules.versNr" hide-details="auto"
                                                      clearable validate-on-blur />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="auto">
                                        <v-radio-group v-model.sync="grund" class="mt-0" mandatory hide-details>
                                            <v-radio v-for="g in gruende" :key="g.Grund" :label="g.Bezeichnung" :value="g.Grund" />
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-2 pb-5 pr-4">
                                    <v-col cols="12">
                                        <v-data-table :items="refundRows" :headers="headers"
                                                      class="font-weight-semibold text-no-wrap text-body-2 text-lg-body-1
                                                        scroll-gradient elevation-2 rounded-0 alternating-row-colors col pa-0"
                                                      disable-pagination>

                                            <template v-slot:item="{ item, index }">
                                                <tr>
                                                    <td>
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td class="px-2">
                                                        <v-text-field v-model="item.belegNr" v-mask="'##################'"
                                                                      autocomplete="off" :rules="rules.belegNr"
                                                                      clearable validate-on-blur />
                                                    </td>
                                                    <td class="px-2 width=50%">
                                                        <v-text-field v-model="item.eRezept" v-mask="'###.###.###.###.###.##'"
                                                                      autocomplete="off" :rules="rules.eRezept"
                                                                      clearable validate-on-blur />
                                                    </td>
                                                    <td class="pl-0 pr-2">
                                                        <v-menu :value="datePickerItem == item" @input="datePickerItem = item"
                                                                :close-on-content-click="false">

                                                            <template v-slot:activator="{ attrs, on }">
                                                                <v-text-field v-bind="attrs" v-on="on" :value="getAbgabeDatum(item)"
                                                                              autocomplete="off"
                                                                              append-icon="$vuetify.icons.calendar"
                                                                              @click:clear="item.aDatum = null" @click:append="datePickerItem = item"
                                                                              clearable readonly />

                                                            </template>
                                                            <v-date-picker v-model="item.aDatum" :min="abgabeDatumMin" :max="abgabeDatumMax"
                                                                           @change="datePickerItem = null" />

                                                        </v-menu>
                                                    </td>
                                                    <td class="pl-0 pr-2">
                                                        <v-currency-field :value="getZuzahlung(item)" @input="(v) => setZuzahlung(item, v)"
                                                                          autocomplete="off" :rules="rules.zuzahlung"
                                                                          :min="0" :max="100000" :auto-decimal-mode="false"
                                                                          append-icon="$vuetify.icons.currencyEuro"
                                                                          clearable validate-on-blur />
                                                    </td>
                                                    <td class="pl-0 pr-2">
                                                        <v-text-field v-model="item.vorgangNr" autocomplete="off"
                                                                      clearable validate-on-blur />

                                                    </td>
                                                    <td class="pl-0 pr-2">
                                                        <v-btn @click="removeRow(item)" :small="$vuetify.breakpoint.mdAndDown" icon>
                                                            <v-icon color="primary">
                                                                $vuetify.icons.delete
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn v-if="index == refundRows.length - 1"
                                                               @click="insertRow(index)" :small="$vuetify.breakpoint.mdAndDown" icon>

                                                            <v-icon color="primary">
                                                                $vuetify.icons.plus
                                                            </v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </template>

                                            <template v-slot:footer.prepend>
                                                <v-btn color="primary" type="button" class="my-2 mr-2"
                                                       @click="uploadFiles.length == 0 ? openFileInput() : showDialogUnterlagen = true">

                                                    <v-icon class="mr-1">mdi-paperclip</v-icon>
                                                    ({{ uploadFiles.length }})
                                                    Dokumente
                                                </v-btn>
                                                <v-btn color="primary" type="button" class="my-2 mr-2"
                                                       @click="showDialogBgText = true">

                                                    <v-icon class="mr-1">mdi-fountain-pen-tip</v-icon>
                                                    Begründungstext
                                                </v-btn>
                                                <div v-if="begruendung" class="text-body-1 text--primary text-truncate mr-2">
                                                    <v-icon class="mr-1">mdi-fountain-pen-tip</v-icon>
                                                    {{ begruendung }}
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="flex-grow-1">
                                        <v-alert v-if="!contextValid || alertMessage" :type="alertType"
                                                 class="my-auto py-3" style="white-space: pre-line;" min-height="52">

                                            {{ alertMessage }}
                                        </v-alert>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn color="primary" type="submit" :disabled="!formFieldsValid || !contextValid" x-large>
                                            Zuzahlungsrückforderung Absenden
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-form>

            </v-col>
        </v-row>

        <!-- Dialog Begründungstext -->
        <v-dialog v-model="showDialogBgText">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>mdi-text-box-outline</v-icon>
                    Begründungstext
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <v-textarea v-model="dlgBgText" placeholder="Bitte geben Sie Ihre Begründung ein!"
                                counter="1000" filled auto-grow clearable />

                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="showDialogBgText = false; begruendung = dlgBgText;">
                        Übernehmen
                    </v-btn>
                    <v-btn color="primary" @click="showDialogBgText = false; dlgBgText = begruendung;">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Unterlagen -->
        <v-dialog v-model="showDialogUnterlagen">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>mdi-paperclip</v-icon>
                    Angefügte Dokumente
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <v-list dense>
                        <v-list-item v-for="(file, i) in uploadFiles" :key="i" class="px-2">
                            <v-list-item-content>
                                <v-list-item-title class="text-body-1">
                                    {{ file.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon @click="previewFile(file)">
                                    $vuetify.icons.fileShow
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-icon>
                                <v-icon @click="deleteFile(file)">
                                    $vuetify.icons.delete
                                </v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="openFileInput">
                        {{ uploadFiles.length == 0 ? 'Neues Dokument' : 'Weitere Dokumente' }}
                    </v-btn>
                    <v-btn color="primary" @click="showDialogUnterlagen = false">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Vergrößertes Image -->
        <v-dialog :value="previewImg" fullscreen>
            <v-container fluid class="fill-height pa-0 white">
                <v-img :src="previewImg" @click="previewImg = null" class="fill-height" contain />
            </v-container>
        </v-dialog>

        <!-- Dialog Einspruch erfolgreich angelegt -->
        <v-dialog v-model="showDialogSuccess">
            <v-card>
                <v-card-title class="text-h5 text-md-h5 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.check</v-icon>
                    Ihre Zuzahlungsrückforderung ist auf dem Weg.
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <p class="text-justify text-hyphenate mb-0">
                        Ihre Zuzahlungsrückforderung wurde erfolgreich angelegt. Diese können Sie im Reiter "Bearbeitungsstatus" einsehen.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="showDialogSuccess = false">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
    class RefundRow {
        belegNr = null;         // string
        eRezept = null;         // string
        aDatum = null;          // string, isoDate
        betrag = null;          // number, in cent
        vorgangNr = null;       // string
    }

    const headers = [
        { text: '#', value: '', width: '1%' },
        { text: 'Belegnummer', value: 'belegNr', width: '33%' },
        { text: 'E-Rezept-ID', value: 'eRezept', width: '33%' },
        { text: 'Abgabedatum', value: 'aDatum', width: '24.75%' },
        { text: 'Gesamtbetrag Zuzahlung', value: 'betrag', width: '24.75%' },
        { text: 'Ihr Kennzeichen', value: 'vorgangNr', width: '24.75%' },
        { text: '', value: '', width: '1%', sortable: false },
    ];

    const belegNrNotOlderThanYears = function (belegNr, maxYears) {
        if (!belegNr || belegNr.length < 1 || Number.isNaN(Number(belegNr)))
            return true;

        const y = parseInt(belegNr[0]);
        const currentYear = new Date().getFullYear();
        const decade = currentYear - currentYear % 10;

        let belegYear = decade + y
        if (belegYear > currentYear)
            belegYear -= 10;

        return Math.abs(belegYear - currentYear) <= maxYears;
    };

    const belegNrHasValidMonth = function (belegNr) {
        if (!belegNr || belegNr.length < 3 || Number.isNaN(Number(belegNr)))
            return true;

        const y = parseInt(belegNr.substring(1, 3));
        return y >= 1 && y <= 12;
    };

    const maxYears = 4;
    const rules = {
        mandant: [v => !!v || 'Der Kostenträger ist erforderlich.',],
        versNr: [
            v => !!v || 'Die Versichertennummer ist erforderlich.',
            v => !v || v.length === 10 || 'Die Versichertennummer muss 10 Stellen lang sein.',
        ],
        belegNr: [
            v => !v || v.length === 18 || 'Die Belegnummer muss 18 Stellen lang sein.',
            v => !v || belegNrNotOlderThanYears(v, maxYears) || `Die Belegnummer darf nicht älter als ${maxYears} Jahre sein (1. Stelle).`,
            v => !v || belegNrHasValidMonth(v) || 'Die Belegnummer muss eine gültige Monatsangabe enthalten (2./3. Stelle).',
        ],
        eRezept: [
            v => !v || v.length === 22 || 'E-Rezept-ID muss 17 Stellen (ohne Punkte) lang sein.',

        ],
        zuzahlung: [v => !!v || 'Der Zuzahlungsbetrag ist erforderlich.',],
    };

    import eventBus from '@/main';
    export default {
        name: 'ZzRefundNew',
        data: () => ({
            // infra
            headers,
            rules,
            datePickerItem: null,
            formFieldsValid: false,
            filePickerActive: false,
            alertMessage: null,
            alertType: 'warning',
            showDialogUnterlagen: false,
            showDialogBgText: false,
            showDialogSuccess: false,
            previewImg: null,
            dlgBgText: null,
            versNrError: null,
            validatingVersNr: false,

            // model
            kostentraeger: null,
            versNr: null,
            grund: 0,
            refundRows: [],         // RefundRow[]
            uploadFiles: [],        // File[]
            begruendung: null,
        }),
        computed: {
            abgabeDatumMin() {
                let min = new Date();
                min.setFullYear(min.getFullYear() - 5);
                return min.toISOString().substr(0, 10);
            },

            abgabeDatumMax() {
                return new Date().toISOString().substr(0, 10);
            },

            mandanten() {
                let ml = this.$store.state.currentUser?.mandanten?.map(m => ({ ...m }));
                //var ix = ml.findIndex(m => m.Mandant === 74)
                //if (ix !== - 1)
                //    ml.splice(ix, 1);
                return ml ?? [];
            },

            gruende() {
                return this.$store.state.defCache?.rueckforderungGrund ?? [];
            },

            contextValid() {
                const errors = [];
                for (let idx = 0; idx < this.refundRows.length; idx++) {
                    const row = this.refundRows[idx];
                    if (!row.belegNr && !row.eRezept && !row.aDatum) {
                        errors.push(`Bitte geben Sie für jede Zeile die Belegnummer (vom Rechenzentrum), E-Rezept-ID oder das Abgabedatum ein.`);
                        break;
                    }
                }

                if (this.grund == 1 && this.uploadFiles.length == 0)
                    errors.push(`Bei Verweigerung der Zuzahlung fügen Sie bitte die Rechnung u./o. Mahnung an.`);

                if (!this.begruendung)
                    errors.push(`Bitte geben Sie eine Begründung an.`);

                if (!this.kostentraeger)
                    errors.push(`Bitte wählen Sie einen Kostenträger aus.`);

                this.alertMessage = errors.join('\r\n');
                return errors.length == 0;
            },
        },
        watch: {
            kostentraeger() {
                this.validateVersNr();
            },
        },
        mounted() {
            this.insertRow(0);
        },
        methods: {

            setVersNr(value) {
                this.versNr = value?.toUpperCase();
                this.validateVersNr();
            },

            async validateVersNr() {
                const isZZR = 1;
                if (!this.validatingVersNr && this.versNr?.length === 10 && this.kostentraeger) {
                    try {
                        this.validatingVersNr = true;
                        const rsp = await this.$http.get('zuz/validateversnr', {
                            params: {
                                mandant: this.kostentraeger,
                                versNr: this.versNr,
                                zzRueckforderung: isZZR
                            }
                        });

                        const msg = 'Die Versichertennummer ist ungültig.';
                        this.versNrError = rsp.data != true ? msg : null;
                    }
                    catch (error) {
                        this.alertMessage = error?.response?.data?.message || "Beim Validieren der Versichertennummer kam es zu einem Fehler.";
                        this.alertType = 'error';
                    }
                    finally {
                        this.validatingVersNr = false;
                    }
                }
                else {
                    this.versNrError = null;
                }
            },

            getAbgabeDatum(item) {

                if (!item.aDatum)
                    return null;

                const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                return new Date(item.aDatum).toLocaleDateString('de-DE', options);
            },

            getZuzahlung(item) {
                return item.betrag ? item.betrag * 0.01 : null;
            },

            setZuzahlung(item, value) {
                item.betrag = value ? Math.round(value * 100) : null;
            },

            insertRow(index) {
                this.refundRows.splice(index + 1, 0, new RefundRow());
            },

            removeRow(item) {
                const index = this.refundRows.findIndex(i => i == item);
                this.refundRows.splice(index, 1);

                if (this.refundRows.length == 0)
                    this.insertRow(0);
            },

            openFileInput() {
                this.$refs.fileInput.click();
            },

            addUploadFiles(e) {
                Array.from(e.target.files).forEach(file => {
                    if (!this.uploadFiles.some(u => u.name == file.name && u.type == file.type && u.size == file.size))
                        this.uploadFiles.push(file);
                });
            },

            deleteFile(file) {
                const idx = this.uploadFiles.findIndex(f => f === file);
                this.uploadFiles.splice(idx, 1);
            },

            previewFile(file) {
                // Make sure `file.name` matches our extensions criteria
                if (/\.(jpe?g|png|gif|pdf)$/i.test(file.name)) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    var newBlob = null;
                    if (/\.(pdf)$/i.test(file.name)) {
                        newBlob = new Blob([file], { type: "application/pdf" });
                        var url = window.URL.createObjectURL(newBlob);
                        window.open(url, '_blank');
                    }
                    else if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
                        let vm = this;
                        reader.addEventListener("load", function () {
                            var image = new Image();
                            image.height = 100;
                            image.title = file.name;
                            image.src = this.result;
                            vm.previewImg = image.src;
                        }, false);
                    }
                    else {
                        console.log('File is not a pdf.', file.type, file);
                        return;
                    }
                }
            },

            async trySubmitZzRefund() {
                if (this.formFieldsValid && this.contextValid) {

                    // do not use the automatic serialization,
                    // else you run in https://github.com/axios/axios/issues/4631
                    const formData = new FormData();
                    formData.append('Payload', JSON.stringify({
                        Kostentraeger: this.kostentraeger,
                        Leistungserbringer: this.$store.state.currentUser.leistungserbringer,
                        VersNr: this.versNr,
                        Grund: this.grund,
                        Begruendung: this.begruendung,
                        Positionen: this.refundRows
                            .map(r => ({
                                BelegNr: r.belegNr,
                                eRezept: r.eRezept,
                                ADatum: r.aDatum,
                                Betrag: r.betrag,
                                VorgangNr: r.vorgangNr,
                            })),
                    }));

                    this.uploadFiles.forEach(r => {
                        formData.append('Anhang', r);
                    });

                    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
                    try {
                        await this.$http.post('zuz/rueckforderung', formData, config);
                        this.resetForm();
                        this.showDialogSuccess = true;
                        eventBus.$emit('reloadZzRefundStatus');
                    }
                    catch (error) {
                        this.alertMessage = error?.response?.data?.message || "Beim Senden der Daten kam es zu einem Fehler.";
                        this.alertType = 'error';
                    }
                }
            },

            resetForm() {
                /* Do not use this.$refs.ZzRefundForm.reset() because it causes 
                 * reactivity a./o. rendering issue with nested data-table!
                 */ 

                this.kostentraeger = null;
                this.versNr = null;
                this.grund = 0;
                this.refundRows = [new RefundRow()];
                this.uploadFiles = [];
                this.begruendung = null;
                this.dlgBgText = null;

                this.$refs.ZzRefundForm.resetValidation();
            },
        },
    };
</script>
<style scoped>
    >>> textarea {
        margin-bottom: 10px;
        margin-right: 10px;
    }

    >>> input[readonly], >>> .v-file-input__text {
        cursor: pointer;
    }

    >>> .v-data-footer {
        flex-wrap: nowrap;
    }

    >>> .v-data-footer__select,
    >>> .v-data-footer__pagination,
    >>> .v-data-footer__icons-before,
    >>> .v-data-footer__icons-after {
        display: none;
    }
</style>